/**
 * Returns a function that wraps the passed thenable function and returns an
 * additional status if there was another request before the result came in
 */

export const functionOutrunStatus = (func) => {
  let lastCalled = Date.now()

  return async (...args) => {
    const callTime = Date.now()
    lastCalled = callTime

    const response = await func(...args)
    return {
      status: callTime < lastCalled ? 'outrun' : 'clear',
      response,
    }
  }
}
export default functionOutrunStatus
