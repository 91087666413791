import React from 'react'

import { ButtonPrimitive } from '../../SubComponents'

import { defaultProps } from './defaultProps'
import { appearance, Icon } from './defaultPropTypes'

export const ButtonDown = ({ appearance, direction, Icon, onClick, onMouseLeave, size, shape }) => {
  return (
    <ButtonPrimitive
      appearance={appearance}
      direction={direction}
      icon={{ Icon, type: 'shady' }}
      iconPosition={{ top: '-1px' }}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      size={size}
      shape={shape}
      tabIndex="-1"
    />
  )
}

ButtonDown.defaultProps = {
  ...defaultProps,
}

ButtonDown.propTypes = {
  appearance,
  Icon,
}
