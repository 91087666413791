import styled from 'styled-components'
import { typography } from 'styled-system'

import { typeScale, typeStyle } from '@typography'

const excludeProps = [
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'typeScale',
  'typeStyle',
]

export const StyledText = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${typography};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};

  display: block;
  max-width: max-content;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;

  & .mouseOverSelector {
    display: none;
    padding-right: 0.5rem;
    position: absolute;
    top: 0;
    z-index: 999;
  }

  &:hover {
    overflow: visible;

    & .mouseOverSelector.is-ellipsed {
      background: #fff;
      box-shadow: 1px 4px 5px 1px #ccc;
      cursor: pointer;
      display: block;
    }
  }
`
