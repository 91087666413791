import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuthzChecks, useDisplaySizeSufficiency } from 'lib/hooks'
import { NavigationMainComponent, NavigationMainComponentFullscreen } from '@ui/NavigationMain'

import LogoRiwisOld from 'components/icons/LogoRiwisOld'
import LogoBulwiengesa from 'components/icons/LogoBulwiengesa'
import LogoBulwiengesaAppraisal from 'assets/images/logos/bulwiengesa_appraisal.svg'
import LogoBulwiengesaLink from 'assets/images/logos/bulwiengesa_logo.svg'
import {
  BudiconPerformanceBoard,
  BudiconTwoGrids,
  BudiconTaskDownload,
  BudiconBubbleChart,
  BudiconChatInformation,
} from 'bgag-budicons'
import { BudiconsWithBackgroundCircle } from '@utilities/SVG/BudiconsWithBackgroundCircle'

export const NavigationMain = memo(() => {
  const { t } = useTranslation()
  const { hasRouteAccess } = useAuthzChecks()

  const [displayIsSufficient] = useDisplaySizeSufficiency()

  const navtree = []

  const addMenuItem = (parent, sublinks) => {
    const subtree = sublinks
      .map(
        (sublink) =>
          hasRouteAccess(sublink.route) && {
            ignoreOnSubtreeCheck: !!sublink?.ignoreOnSubtreeCheck,
            label: sublink.label,
            description: sublink.description,
            link: {
              path: sublink.route,
              href: sublink.href ?? null,
            },
            icon: sublink.icon,
            image: sublink.image,
          }
      )
      .filter(Boolean)

    if (subtree.filter((sublink) => !sublink?.ignoreOnSubtreeCheck).length) {
      parent.icon.color = 'colors.theme.navigationMain.parent.icon.foreground.default'
      const addSubtree =
        displayIsSufficient || parent.subtreeOnInsufficientDisplay
          ? subtree.filter((item) => displayIsSufficient || item.link.path.endsWith('/home'))
          : []
      navtree.push({
        parent: {
          label: parent.label,
          icon: parent.icon,
          iconPosition: parent.iconPosition,
          route: parent.route,
          subtree: addSubtree,
        },
      })
    }
  }

  addMenuItem(
    {
      label: t('modules.marketAnalysis.name'),
      icon: {
        component: <BudiconPerformanceBoard />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['1px', null, null, '0px'],
          top: ['1px', null, null, '3px'],
        },
        size: ['19px', null, null, '20px'],
      },
      route: '/market-analysis/home',
    },
    [
      {
        label: t('modules.market-data.name'),
        route: '/market-analysis/market-data',
        description: t('modules.market-data.description'),
        icon: <BudiconsWithBackgroundCircle name="Markets" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.objects.name'),
        route: '/market-analysis/objects',
        description: t('modules.objects.description'),
        icon: <BudiconsWithBackgroundCircle name="Objects" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.city-structure.name'),
        route: '/market-analysis/city-structure',
        description: t('modules.city-structure.description'),
        icon: <BudiconsWithBackgroundCircle name="MarketAnalysis" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.marketText.name'),
        route: '/market-analysis/market-text',
        description: t('modules.marketText.description'),
        icon: <BudiconsWithBackgroundCircle name="MarketTexts" iconVariantSourceName="navigationIcons" />,
      },
    ]
  )

  addMenuItem(
    {
      label: t('modules.objects.name'),
      icon: {
        component: <LogoRiwisOld />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['4px', null, null, '3px'],
          top: ['2px', null, null, '3px'],
        },
        size: ['18px', null, null, '19px'],
      },
      route: '/objects/home',
    },
    [
      {
        label: t('modules.comparables.name'),
        route: '/objects/comparables',
        description: t('modules.comparables.description'),
        icon: <BudiconsWithBackgroundCircle name="Markets" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.pipeline.name'),
        route: '/objects/pipeline',
        description: t('modules.pipeline.description'),
        icon: <BudiconsWithBackgroundCircle name="Objects" iconVariantSourceName="navigationIcons" />,
      },
    ]
  )

  addMenuItem(
    {
      label: t('modules.macro.name'),
      icon: {
        component: <BudiconBubbleChart />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['2px', null, null, '1px'],
          top: ['0', null, null, '1px'],
        },
        size: ['17px', null, null, '19px'],
      },
      route: '/makro/home',
    },
    [
      {
        label: t('modules.economy.name'),
        route: '/makro/economy',
        description: t('modules.economy.description'),
        icon: <BudiconsWithBackgroundCircle name="Markets" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.immoIndex.name'),
        route: '/makro/propertyindices',
        description: t('modules.immoIndex.description'),
        icon: <BudiconsWithBackgroundCircle name="Makro" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.thematicMaps.name'),
        route: '/makro/thematic-maps',
        description: t('modules.thematicMaps.description'),
        icon: (
          <BudiconsWithBackgroundCircle
            name="Telescope"
            iconVariantSourceName="navigationIcons"
            backgroundColor="#F1CF56"
          />
        ),
      },
    ]
  )

  addMenuItem(
    {
      label: t('modules.apps.name'),
      icon: {
        component: <BudiconTwoGrids />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['2px', null, null, '1px'],
          top: ['1px', null, null, '2px'],
        },
        size: ['17px', null, null, '19px'],
      },
      subtreeOnInsufficientDisplay: true,
      route: '/features/home',
    },
    [
      {
        label: t('modules.scoring.name'),
        // route: '/features/scoring',
        route: '/features/scoring/assetclasses',
        description: t('modules.scoring.description'),
        icon: <BudiconsWithBackgroundCircle name="Disco" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.developer.name'),
        route: '/features/developer/home',
        description: t('modules.developer.description'),
        icon: <BudiconsWithBackgroundCircle name="Developer" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.hedonic.name'),
        route: '/features/hedonic/home',
        description: t('modules.hedonic.description'),
        icon: <BudiconsWithBackgroundCircle name="Prospect" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.l3plus.name'),
        route: '/features/l3plus/home',
        description: t('modules.l3plus.description'),
        icon: <BudiconsWithBackgroundCircle name="L3Plus" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.retailMarkets.name'),
        route: '/features/retail-markets/home',
        description: t('modules.retailMarkets.description'),
        icon: <BudiconsWithBackgroundCircle name="RetailMarkets" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.disco.name'),
        route: '/features/disco',
        description: t('modules.disco.description'),
        icon: <BudiconsWithBackgroundCircle name="Disco" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.highstreetReport.name'),
        route: '/features/highstreet-report/home',
        description: t('modules.highstreetReport.description'),
        icon: (
          <BudiconsWithBackgroundCircle name="HighstreetReport" iconVariantSourceName="navigationIcons" />
        ),
      },
    ]
  )

  addMenuItem(
    {
      label: t('modules.download.name'),
      icon: {
        component: <BudiconTaskDownload />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['1px', null, null, '0'],
          top: ['0', null, null, '1px'],
        },
        size: ['19px', null, null, '20px'],
      },
      route: '/download/home',
    },
    [
      {
        ignoreOnSubtreeCheck: true,
        label: t('modules.documents.name'),
        route: '/download/documents',
        description: t('modules.documents.description'),
        icon: (
          <BudiconsWithBackgroundCircle
            name="Headline"
            iconVariantSourceName="navigationIcons"
            backgroundColor={'#AAC0CC'}
          />
        ),
      },
      {
        label: t('modules.rawData.name'),
        route: '/download/raw-data',
        description: t('modules.rawData.description'),
        icon: (
          <BudiconsWithBackgroundCircle
            name="Booklet"
            iconVariantSourceName="navigationIcons"
            backgroundColor={'#AAC0CC'}
          />
        ),
      },
      {
        label: t('modules.profile.name'),
        route: '/download/profile',
        description: t('modules.profile.description'),
        icon: (
          <BudiconsWithBackgroundCircle
            name="Download"
            iconVariantSourceName="navigationIcons"
            backgroundColor={'#AAC0CC'}
          />
        ),
      },
      {
        label: t('modules.documentation.name'),
        route: '/download/documentation',
      },
    ]
  )

  addMenuItem(
    {
      label: t('menu.informations'),
      icon: {
        component: <BudiconChatInformation />,
        color: 'colors.theme.navigationMain.parent.icon.foreground.default',
        iconPosition: {
          left: ['2px', null, null, '1px'],
          top: ['2px', null, null, '2px'],
        },
        size: ['17px', null, null, '19px'],
      },
      route: '/information/home',
    },
    [
      {
        label: t('modules.glossary.name'),
        route: '/information/glossary',
        description: t('modules.glossary.description'),
        icon: <BudiconsWithBackgroundCircle name="Information" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.schedule.name'),
        route: '/information/schedule',
        description: t('modules.schedule.description'),
        icon: <BudiconsWithBackgroundCircle name="Calendar" iconVariantSourceName="navigationIcons" />,
      },
      {
        label: t('modules.marketDataPartners.name'),
        route: '/information/market-data-partners',
        description: t('modules.marketDataPartners.description'),
        icon: <BudiconsWithBackgroundCircle name="Handshake" iconVariantSourceName="navigationIcons" />,
      },
    ]
  )

  addMenuItem(
    {
      icon: {
        component: <LogoBulwiengesa part="icon" size="18px" />,
        iconPosition: {
          left: ['2px', null, null, '1px'],
          top: ['2px', null, null, '2px'],
        },
        size: ['17px', null, null, '19px'],
      },
      label: t('menu.bulwiengesa'),
      route: '#',
    },
    [
      {
        image: {
          src: LogoBulwiengesaLink,
          alt: t('modules.bulwiengesa.name'),
        },
        route: '',
        href: 'https://bulwiengesa.de',
      },
      {
        image: {
          src: LogoBulwiengesaAppraisal,
          alt: t('modules.appraisal.name'),
        },
        route: '',
        href: 'https://bulwiengesa.de/de/bulwiengesa-appraisal',
      },
    ]
  )

  if (displayIsSufficient) {
    return <NavigationMainComponent navtree={navtree} />
  } else {
    return <NavigationMainComponentFullscreen navtree={navtree} />
  }
})
