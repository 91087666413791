import { system } from 'styled-system'
import { typeStyles } from './typeStyles'
import { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { getThemeValue } from 'theming'

export const typeStyle = (name) => {
  return typeStyles[name]
}

// If typeScale is used, the using component needs fontSize=[0,1,2,3,4] as property to allow mapping between scale and theme value
export const typeScale = (scale) =>
  system({
    fontSize: {
      property: 'fontSize',
      scale: scale,
    },
  })

export const generateFontSizeMediaQuery = (scale = 'copy', important = false) => css`
  font-size: ${(props) => themeGet(scale)(props)[0]} ${important ? '!important' : ''};

  ${(props) => {
    const fontSizes = themeGet(scale)(props)
    return themeGet('breakpoints')(props).map(
      (breakpoint, index) => `@media screen and (min-width:${breakpoint}) {
        font-size: ${fontSizes[index + 1]} ${important ? '!important' : ''};
      }`
    )
  }};
`
export const getFontSizeByBeakpoints = (theme, scale = 'copy') => {
  const breakpoints = getThemeValue(theme, 'breakpoints').map((breakpoint) =>
    Number(breakpoint.replace('px', ''))
  )
  const fontSizes = getThemeValue(theme, scale)
  const fontSizeByBreakpoints = breakpoints.reduce((accObj, breakpoint, index) => {
    accObj[breakpoint] = fontSizes[index + 1]
    return accObj
  }, {})
  fontSizeByBreakpoints[0] = fontSizes[0]

  return fontSizeByBreakpoints
}
