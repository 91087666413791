import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionalsRawDataSources = []
const getterRawDataSources = () => {
  return api.Config.byType('rawDataSources').then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsAreas = []
const getterAreas = () => {
  return api.Areas.all().then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsDataSourcesYears = []
const getterDataSourcesYears = () => {
  return api.Config.dataSourcesYears('riwisCity').then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsTopics = []
const getterTopics = () => {
  return api.Config.topics(true).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getRawDataSources, bustRawDataSources] = createDataCache(
  conditionalsRawDataSources,
  getterRawDataSources,
  {
    name: 'RawDataSources',
  }
)

export const [getAreas, bustAreas] = createDataCache(conditionalsAreas, getterAreas, {
  name: 'RawDataAreas',
})

export const [getDataSourcesYears, bustDataSourcesYears] = createDataCache(
  conditionalsDataSourcesYears,
  getterDataSourcesYears,
  {
    name: 'RawDataSourcesYears',
  }
)

export const [getTopics, bustTopics] = createDataCache(conditionalsTopics, getterTopics, {
  name: 'RawDataTopics',
})
